/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        var $doc = $(document);

        // Determines current Bootstrap breakpoint by seeing if an element is visible.
        // Those elements are not visible on page, only used for this purpose.
        function isBreakpoint(breakpoint) {
          return $('.device-'+breakpoint).is(':visible');
        }
        $("img.lazy").lazy( {
          afterLoad: function(element) {
            console.log($(this));
            $doc.trigger('lazyloaded');
            $(this).addClass("lazyloaded");
          }
        });
        /*$("img.lazy").lazyload({
          placeholder: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEX///+nxBvIAAAACklEQVQI12NgAAAAAgAB4iG8MwAAAABJRU5ErkJggg==",
          load: function() {
            $doc.trigger('lazyloaded');
            $(this).addClass("lazyloaded");
          }
        });*/

        $('.gallery-image').magnificPopup({
          type:'image',
          closeBtnInside: false,
          closeMarkup: '<div class="close"></div>',
          gallery: {
            enabled: true,
            arrowMarkup: '<button title="%title%" type="button" class="popup-arrow popup-arrow-%dir%"></button>',
            tCounter: '<span class="mfp-counter">%curr% / %total%</span>'
          }
        });

        setTimeout(function() {
          var waypoint = $('.animate-in:not(.animate-start)')
            .not('[data-init-cssanimation]')
            .attr('data-init-cssanimation', true).waypoint(function() {
              var $el = $(this.element);
              return $el.addClass('animate-start');
            }, {
              offset: '75%'
            });

          $doc.on('lazyloaded', function() {
            Waypoint.refreshAll();
          });
          $('.animate-in').first().addClass('animate-start');
        }, 200);

        $('.featured-items__show-additional').on('click', function(e) {
          e.preventDefault();
          $('[data-term-id="'+$(this).data('term-id-to-show')+'"]').fadeIn();
          $(window).trigger("scroll");
          $(this).remove();
        });

        if($('.site-banner').length > 0) {
          $videoLoop = $('.site-banner');
          if(typeof($videoLoop.attr('data-original')) !== 'undefined') {
            $videoLoop.css('background-image', 'url("'+$videoLoop.data('original')+'")');
          }
          if (typeof($videoLoop.attr('data-video-src')) !== 'undefined' && $videoLoop.attr('data-video-src') !== '') {
            if (isBreakpoint('sm') || isBreakpoint('md') || isBreakpoint('lg')) {
              $videoLoop.replaceWith('<video class="site-banner" ' + ($videoLoop.data('original') ? 'poster="' + $videoLoop.data('original') + '"' : '') + ' autoplay loop muted><source src="' + $videoLoop.data('video-src') + '" type="video/mp4"></video>');
            }
          }
        }

        // Open search popup
        $('.nav-bar .search-button').click(function() {
          $('.search-wrapper').toggleClass('open');
          $('.site-header__logo').toggleClass('fixed');
          if ($('.search-wrapper.open').length) {
            $('.search-wrapper input[type="text"]').focus();
          }
          if (isBreakpoint('xs') || isBreakpoint('sm') || isBreakpoint('md')) {
            $('body').toggleClass('navigation-is-open');
          }
        });

        // Close search popup if Esc is pressed
        $(document).keyup(function(e) {
          if (e.keyCode === 27) { // esc
            if ($('.search-wrapper.open').length) {
              $('.search-wrapper').toggleClass('open');
              $('.site-header__logo').toggleClass('fixed');
            }
          }
        });

        // Close search popup on with the X button
        $('.search .close').click(function() {
          $('.search-wrapper').toggleClass('open');
          $('.site-header__logo').toggleClass('fixed');
        });

        var isLateralNavAnimating = false;

        //open/close lateral navigation
        $('.cd-nav-trigger').on('click', function(event){
          event.preventDefault();
          //stop if nav animation is running
          if( !isLateralNavAnimating ) {
            if($(this).parents('.csstransitions').length > 0 ) {
              isLateralNavAnimating = true;
            }

            $('body').toggleClass('navigation-is-open');
            $('.cd-navigation-wrapper').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function(){
              //animation is over
              isLateralNavAnimating = false;
            });
          }
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        // Close front page news popup
        $('.front-popup .close').click(function() {
          $(this).closest('.front-popup').toggleClass('open');
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'stream': {
      init: function() {

        // $('.unslider').unslider({
        //   animateHeight: true
        // });

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');

      // Custom search box
      // new UISearch( document.getElementById( 'sb-search'
    }
  };

  // Load Events
  $(document).ready(function() {

    UTIL.loadEvents();

  });


})(jQuery); // Fully reference jQuery after this point.
